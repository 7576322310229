<template>
  <AppHeader />
  <component :is="currentView" />
  <AppFooter />
</template>

<script setup>
import { ref, computed, watch} from "vue";
import { useI18n } from 'vue-i18n'
import { globalState } from "./global";

import AppHeader from "@/components/partials/AppHeader.vue";

import HomePage from "@/components/HomePage.vue";
import AboutUs from "@/components/AboutUs.vue";
import MedicalPrescribers from "@/components/MedicalPrescribers.vue";
import PharmaceuticalIndustry from "@/components/PharmaceuticalIndustry.vue";
import AppPharmacies from "@/components/AppPharmacies.vue";
import PublicHealthSystem from "@/components/PublicHealthSystem.vue";
import TargetAudiences from "@/components/TargetAudiences.vue";
import AppServices from "@/components/AppServices.vue";

import AppFooter from "@/components/partials/AppFooter.vue";
import AppBlog from "@/components/AppBlog.vue";
import AppContact from "@/components/AppContact.vue";
import AppEcommerce from "@/components/AppEcommerce.vue";
import AppAssociations from "@/components/AppAssociations.vue";
import AppPatients from "@/components/AppPatients.vue";
import HealthOperators from "@/components/HealthOperators.vue";
import Hospitals from "@/components/AppHospitals.vue";

const routes = {
  '/': HomePage,
  '/quem-somos': AboutUs,
  '/prescritores-medicos': MedicalPrescribers,
  '/industria-farmaceutica': PharmaceuticalIndustry,
  '/farmacias-drogarias': AppPharmacies,
  '/sistema-publico-de-saude': PublicHealthSystem,
  '/publico-alvo': TargetAudiences,
  '/servicos': AppServices,
  '/blog': AppBlog,
  '/contato': AppContact,
  '/associacoes-doencas-raras': AppAssociations,
  '/pacientes': AppPatients,
  '/operadoras-saude': HealthOperators,
  '/hospitais': Hospitals,
  '/en/about': AboutUs,
  '/en/medical-prescribers': MedicalPrescribers,
  '/en/pharmaceutical-industry': PharmaceuticalIndustry,
  '/en/pharmacies': AppPharmacies,
  '/en/public-health-system': PublicHealthSystem,
  '/en/target-audience': TargetAudiences,
  '/en/services': AppServices,
  '/en/blog': AppBlog,
  '/en/contact': AppContact,
  '/ecommerce': AppEcommerce,
  '/en/ecommerce': AppEcommerce,
  '/en/associations': AppAssociations,
  '/en/patients': AppPatients,
  '/en/health-operators': HealthOperators,
  '/en/hospitals': Hospitals,
}

const { locale } = useI18n()
globalState.currentLang = locale.value

watch(() => locale.value, (newLang) => {
  globalState.currentLang = newLang
})

const currentPath = ref(window.location.pathname);

const currentView = computed(() => {
  return routes[currentPath.value] || HomePage;
});

window.addEventListener('popstate', () => {
  currentPath.value = window.location.pathname;
});
</script>

<style>

</style>
