import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import i18n from './i18n'
import { createMetaManager } from 'vue-meta';
import AOS from 'aos'

const app = createApp(App)

app.use(router);
app.use(i18n);
app.use(createMetaManager()); // Correctly initialize vue-meta
app.mount('#app');

AOS.init();
