<script setup>
import { useI18n } from 'vue-i18n'

const { t } = useI18n()
</script>

<template>
  <div>
    <main>
      <section class="hero">
        <div class="container">
          <div class="content" data-aos="zoom-in" data-aos-duration="2000">
            <h1>{{ t('about.title') }} <span>{{ t('about.subtitle') }}</span></h1>
          </div>
        </div>
      </section>

      <section class="intro">
        <div class="container">
          <div class="content">
            <div class="-text">
              <h2>{{ t('about.section1.title') }}</h2>
              <p v-html="t('about.section1.content')"></p>
            </div>
            <div class="-image" data-aos="flip-right" data-aos-easing="ease-out-cubic" data-aos-duration="2000">
              <img src="@/assets/img/about/sobre-a-mypharma2go-bg.jpg" alt="About MyPharma2GO">
            </div>
          </div>
        </div>
      </section>

      <section class="history">
        <div class="container">
          <div class="content">
            <div class="-text" data-aos="flip-left" data-aos-easing="ease-out-cubic" data-aos-duration="2000">
              <h2>{{ t('about.section2.title') }}</h2>
              <p v-html="t('about.section2.content')"></p>
            </div>
            <div class="-mvv">
              <div class="-item">
                <h3>{{ t('about.mission') }}</h3>
                <p>{{ t('about.mission_content') }}</p>
              </div>
              <div class="-item">
                <h3>{{ t('about.vision') }}</h3>
                <p>{{ t('about.vision_content') }}</p>
              </div>
              <div class="-item">
                <h3>{{ t('about.values') }}</h3>
                <ul>
                  <li v-html="t('about.values_list.commitment')"></li>
                  <li v-html="t('about.values_list.transparency')"></li>
                  <li v-html="t('about.values_list.innovation')"></li>
                  <li v-html="t('about.values_list.excellence')"></li>
                  <li v-html="t('about.values_list.costs')"></li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
    </main>
  </div>
</template>

<script>
export default {
  name: "AboutUs",
  data() {
    return {
      bodyClass: 'about',
    }
  },
  mounted() {
    document.body.classList.add(this.bodyClass)
  },
  beforeUnmount() {
    document.body.classList.remove(this.bodyClass)
  },
}
</script>

<style scoped>
/* Add necessary styles here */
</style>
