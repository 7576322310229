<script setup>
import { useI18n } from 'vue-i18n'

const { t } = useI18n()
</script>

<template>
  <div>
    <main>
      <section class="hero">
        <div class="container">
          <div class="content" data-aos="zoom-in" data-aos-duration="2000">
            <h1>{{ t('target_audiences.hero.title') }} <span>{{ t('target_audiences.hero.subtitle') }}</span></h1>
          </div>
        </div>
      </section>

      <section class="segments">
        <div class="container">
          <div class="intro">
            <h2>{{ t('target_audiences.segments.title') }}</h2>
            <p>{{ t('target_audiences.segments.description') }}</p>
          </div>

          <div class="items">
            <div class="-item" v-for="(segment, index) in segments" :key="index" :data-aos="'fade-in'" :data-aos-delay="(index + 1) * 50" data-aos-duration="1500">
              <img :src="segment.image" :alt="t(segment.alt)">
              <h4>{{ t(segment.title) }}</h4>
              <p>{{ t(segment.description) }}</p>
              <a :href="t(segment.link)" class="button-white">{{ t('target_audiences.learn_more') }}</a>
            </div>
          </div>
        </div>
      </section>
    </main>
  </div>
</template>

<script>
export default {
  name: "TargetAudiences",
  data() {
    return {
      bodyClass: 'public',
      segments: [
        {
          image: '../assets/img/icon-industria-farmaceutica.svg',
          alt: 'target_audiences.segments.pharmaceutical_industry.alt',
          title: 'target_audiences.segments.pharmaceutical_industry.title',
          description: 'target_audiences.segments.pharmaceutical_industry.description',
          link: 'target_audiences.links.pharmaceutical_industry'
        },
        {
          image: '../assets/img/icon-operadoras-de-saude.svg',
          alt: 'target_audiences.segments.health_operators.alt',
          title: 'target_audiences.segments.health_operators.title',
          description: 'target_audiences.segments.health_operators.description',
          link: 'target_audiences.links.health_operators'
        },
        {
          image: '../assets/img/icon-hospitais-publicos-e-privados.svg',
          alt: 'target_audiences.segments.hospitals.alt',
          title: 'target_audiences.segments.hospitals.title',
          description: 'target_audiences.segments.hospitals.description',
          link: 'target_audiences.links.hospitals'
        },
        {
          image: '../assets/img/icon-farmacias-e-drogarias.svg',
          alt: 'target_audiences.segments.pharmacies.alt',
          title: 'target_audiences.segments.pharmacies.title',
          description: 'target_audiences.segments.pharmacies.description',
          link: 'target_audiences.links.pharmacies_drugstores'
        },
        {
          image: '../assets/img/icon-associacoes-apoio-doencas-raras.svg',
          alt: 'target_audiences.segments.rare_disease_associations.alt',
          title: 'target_audiences.segments.rare_disease_associations.title',
          description: 'target_audiences.segments.rare_disease_associations.description',
          link: 'target_audiences.links.rare_disease_associations'
        },
        {
          image: '../assets/img/icon-sistema-publico-de-saude.svg',
          alt: 'target_audiences.segments.public_health_system.alt',
          title: 'target_audiences.segments.public_health_system.title',
          description: 'target_audiences.segments.public_health_system.description',
          link: 'target_audiences.links.public_health_system'
        },
        {
          image: '../assets/img/icon-prescritores.svg',
          alt: 'target_audiences.segments.prescribers.alt',
          title: 'target_audiences.segments.prescribers.title',
          description: 'target_audiences.segments.prescribers.description',
          link: 'target_audiences.links.prescribers'
        },
        {
          image: '../assets/img/icon-pacientes.svg',
          alt: 'target_audiences.segments.patients.alt',
          title: 'target_audiences.segments.patients.title',
          description: 'target_audiences.segments.patients.description',
          link: 'target_audiences.links.patients'
        }
      ]
    }
  },
  mounted() {
    document.body.classList.add(this.bodyClass)
  },
  beforeUnmount() {
    document.body.classList.remove(this.bodyClass)
  },
}
</script>

<style scoped>
/* Add necessary styles here */
</style>
