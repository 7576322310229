import { createRouter, createWebHistory } from 'vue-router'
import HomePage from '../components/HomePage.vue'
import AboutUs from '../components/AboutUs.vue'
import MedicalPrescribers from "@/components/MedicalPrescribers.vue";
import PharmaceuticalIndustry from "@/components/PharmaceuticalIndustry.vue";
import PublicHealthSystem from "@/components/PublicHealthSystem.vue";
import TargetAudiences from "@/components/TargetAudiences.vue";
import i18n from '../i18n'
import AppPharmacies from "@/components/AppPharmacies.vue";
import AppBlog from "@/components/AppBlog.vue";
import AppContact from "@/components/AppContact.vue";
import AppServices from "@/components/AppServices.vue";
import AppEcommerce from "@/components/AppEcommerce.vue";
import AppAssociations from "@/components/AppAssociations.vue";
import AppPatients from "@/components/AppPatients.vue";
import HealthOperators from "@/components/HealthOperators.vue";
import Hospitals from "@/components/AppHospitals.vue";

const routes = [
    {
        path: '/:lang(pt|en)?',
        component: {
            template: '<router-view></router-view>'
        },
        children: [
            {
                path: '',
                name: 'HomePage',
                component: HomePage
            },
            {
                path: 'en/about',
                name: 'AboutUs',
                component: AboutUs
            },
            {
                path: 'quem-somos',
                name: 'AboutUsPT',
                component: AboutUs
            },
            {
                path: 'en/medical-prescribers',
                name: 'MedicalPrescribers',
                component: MedicalPrescribers
            },
            {
                path: 'prescritores-medicos',
                name: 'MedicalPrescribersPT',
                component: MedicalPrescribers
            },
            {
                path: 'en/pharmaceutical-industry',
                name: 'PharmaceuticalIndustry',
                component: PharmaceuticalIndustry
            },
            {
                path: 'industria-farmaceutica',
                name: 'PharmaceuticalIndustryPT',
                component: PharmaceuticalIndustry
            },
            {
                path: 'en/pharmacies',
                name: 'Pharmacies',
                component: AppPharmacies
            },
            {
                path: 'farmacias-drogarias',
                name: 'PharmaciesPT',
                component: AppPharmacies
            },
            {
                path: 'en/public-health-system',
                name: 'PublicHealthSystem',
                component: PublicHealthSystem
            },
            {
                path: 'sistema-publico-de-saude',
                name: 'PublicHealthSystemPT',
                component: PublicHealthSystem
            },
            {
                path: 'en/target-audience',
                name: 'TargetAudiences',
                component: TargetAudiences
            },
            {
                path: 'publico-alvo',
                name: 'TargetAudiencesPT',
                component: TargetAudiences
            },
            {
                path: 'blog',
                name: 'AppBlog',
                component: AppBlog
            },
            {
                path: 'en/contact',
                name: 'AppContact',
                component: AppContact
            },
            {
                path: 'contato',
                name: 'AppContactPT',
                component: AppContact
            },
            {
                path: 'en/services',
                name: 'AppServices',
                component: AppServices
            },
            {
                path: 'servicos',
                name: 'AppServicesPT',
                component: AppServices
            },
            {
                path: 'en/ecommerce',
                name: 'AppEcommerce',
                component: AppEcommerce
            },
            {
                path: 'ecommerce',
                name: 'AppEcommercePT',
                component: AppEcommerce
            },
            {
                path: 'en/associations',
                name: 'AppAssociations',
                component: AppAssociations
            },
            {
                path: 'associacoes-doencas-raras',
                name: 'AppAssociationsPT',
                component: AppAssociations
            },
            {
                path: 'en/patients',
                name: 'AppPatients',
                component: AppPatients
            },
            {
                path: 'pacientes',
                name: 'AppPatientsPT',
                component: AppPatients
            },
            {
                path: 'en/health-operators',
                name: 'HealthOperators',
                component: HealthOperators
            },
            {
                path: 'operadoras-saude',
                name: 'HealthOperatorsPT',
                component: HealthOperators
            },
            {
                path: 'en/hospitals',
                name: 'Hospitals',
                component: Hospitals
            },
            {
                path: 'hospitais',
                name: 'HospitalsPT',
                component: Hospitals
            }
        ]
    }
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})

router.beforeEach((to, from, next) => {
    const lang = to.path.includes('/en') ? 'en' : 'pt';
    i18n.global.locale = lang;
    next();
});

export default router
