<script setup>
import { useI18n } from 'vue-i18n'

const { t } = useI18n()
</script>

<template>
  <div>
    <footer>
      <div class="container">
        <div class="items">
          <div class="-item -mp2go">
            <p><small>{{ t('footer.priority') }}</small></p>
            <img src="@/assets/img/logo-mp2go.svg" alt="MP2GO">

            <ul>
              <li><a href="https://www.linkedin.com/company/mypharma2go/" target="_blank"><i class="fa-brands fa-linkedin"></i></a></li>
            </ul>
          </div>

          <div class="-item">
            <h4>{{ t('footer.attendance') }}</h4>

            <ul>
              <li><a href="https://api.whatsapp.com/send/?phone=5511998680834" target="_blank">+55 11 9 9868 0834</a></li>
              <li><a href="mailto:atendimento@mypharma2go.com">atendimento@mypharma2go.com</a></li>
            </ul>
          </div>

          <div class="-item">
            <h4>{{ t('footer.quick_access') }}</h4>

            <ul>
              <li>
                <a href="/index" class="active">{{ t('footer.links.home') }}</a>
              </li>
              <li>
                <a href="/quem-somos">{{ t('footer.links.about_us') }}</a>
              </li>
              <li>
                <a href="/servicos">{{ t('footer.links.services') }}</a>
              </li>
              <li>
                <a href="/publicos-atendidos">{{ t('footer.links.audiences') }}</a>
              </li>
              <li>
                <a href="/blog">{{ t('footer.links.blog') }}</a>
              </li>
              <li>
                <a href="/contato">{{ t('footer.links.contact') }}</a>
              </li>
            </ul>
          </div>
        </div>

        <div class="copy">
          <p>Copyright <i class="fa-regular fa-copyright"></i> 2025 <a href="index">MyPharma2GO</a>. {{ t('footer.rights_reserved') }}</p>
        </div>
      </div>
    </footer>

    <div class="overlay"></div>
  </div>
</template>

<style scoped>
/* Adicione aqui os estilos CSS do seu componente */
</style>
