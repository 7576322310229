<script setup>
import { useI18n } from 'vue-i18n'

const { t } = useI18n()
</script>

<template>
  <div>
    <main>
      <section class="hero">
        <div class="container">
          <div class="content" data-aos="zoom-in" data-aos-duration="2000">
            <h1>{{ t('services.hero.title') }} <span>{{ t('services.hero.subtitle') }}</span></h1>
          </div>
        </div>
      </section>

      <section class="solutions">
        <div class="container">
          <div class="intro">
            <h2>{{ t('services.solutions.title') }}</h2>
            <p>{{ t('services.solutions.description') }}</p>
          </div>

          <div class="items">
            <div class="-item" v-for="(service, index) in services" :key="index" :data-aos="'fade-in'" :data-aos-delay="(index + 1) * 50" data-aos-duration="1500">
              <img :src="service.image" :alt="t(service.alt)">
              <h4>{{ t(service.title) }}</h4>
              <p>{{ t(service.description) }}</p>
            </div>
          </div>
        </div>
      </section>

      <section class="benefits">
        <div class="container">
          <div class="content">
            <h2>{{ t('services.benefits.title') }}</h2>
            <p>{{ t('services.benefits.description1') }}</p>
            <p>{{ t('services.benefits.description2') }}</p>
            <ul>
              <li><strong>{{ t('services.benefits.item1.title') }}</strong> {{ t('services.benefits.item1.description') }}</li>
              <li><strong>{{ t('services.benefits.item2.title') }}</strong> {{ t('services.benefits.item2.description') }}</li>
              <li><strong>{{ t('services.benefits.item3.title') }}</strong> {{ t('services.benefits.item3.description') }}</li>
              <li><strong>{{ t('services.benefits.item4.title') }}</strong> {{ t('services.benefits.item4.description') }}</li>
              <li><strong>{{ t('services.benefits.item5.title') }}</strong> {{ t('services.benefits.item5.description') }}</li>
            </ul>
          </div>
        </div>
      </section>

      <section class="cta">
        <div class="container">
          <p>{{ t('services.cta.text') }}</p>
          <a href="contato" class="button">{{ t('services.cta.button') }}</a>
        </div>
      </section>
    </main>
  </div>
</template>

<script>
export default {
  name: "AppServices",
  data() {
    return {
      bodyClass: 'services',
      services: [
        {
          image: "../assets/img/services/icon-importacao.png",
          alt: "services.solutions.items.import.alt",
          title: "services.solutions.items.import.title",
          description: "services.solutions.items.import.description"
        },
        {
          image: "../assets/img/services/icon-medicamentos-sob-demanda.png",
          alt: "services.solutions.items.demand.alt",
          title: "services.solutions.items.demand.title",
          description: "services.solutions.items.demand.description"
        },
        {
          image: "../assets/img/services/icon-solucoes-demandas-judiciais.png",
          alt: "services.solutions.items.judicial.alt",
          title: "services.solutions.items.judicial.title",
          description: "services.solutions.items.judicial.description"
        },
        {
          image: "../assets/img/services/icon-solucoes-para-licitacoes.png",
          alt: "services.solutions.items.bidding.alt",
          title: "services.solutions.items.bidding.title",
          description: "services.solutions.items.bidding.description"
        },
        {
          image: "../assets/img/services/icon-consolidacao-pedidos.png",
          alt: "services.solutions.items.consolidation.alt",
          title: "services.solutions.items.consolidation.title",
          description: "services.solutions.items.consolidation.description"
        },
        {
          image: "../assets/img/services/icon-planejamento-estrategico.png",
          alt: "services.solutions.items.planning.alt",
          title: "services.solutions.items.planning.title",
          description: "services.solutions.items.planning.description"
        },
        {
          image: "../assets/img/services/icon-educacao-e-suporte.png",
          alt: "services.solutions.items.education.alt",
          title: "services.solutions.items.education.title",
          description: "services.solutions.items.education.description"
        }
      ]
    }
  },
  mounted() {
    document.body.classList.add(this.bodyClass)
  },
  beforeUnmount() {
    document.body.classList.remove(this.bodyClass)
  }
}
</script>

<style scoped>
/* Add necessary styles here */
</style>
