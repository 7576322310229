<script setup>
import { useI18n } from 'vue-i18n'

const { t } = useI18n()
</script>

<template>
  <div>
    <main>
      <section class="hero">
        <div class="container">
          <div class="content" data-aos="zoom-in" data-aos-duration="2000">
            <h1>{{ t('contact.hero.title') }} <span>{{ t('contact.hero.subtitle') }}</span></h1>
          </div>
        </div>
      </section>

      <section class="intro">
        <div class="container">
          <div class="content">
            <div class="-form">
              <h2>{{ t('contact.intro.title') }}</h2>
              <p>{{ t('contact.intro.description') }}</p>
              <form action="" method="post">
                <fieldset>
                  <div class="field">
                    <label for="nome">{{ t('contact.form.name') }}</label>
                    <input type="text" name="nome" id="nome">
                  </div>
                  <div class="field -half">
                    <div>
                      <label for="email">{{ t('contact.form.email') }}</label>
                      <input type="email" name="email" id="email">
                    </div>
                    <div>
                      <label for="telefone">{{ t('contact.form.phone') }}</label>
                      <input type="tel" name="telefone" id="telefone">
                    </div>
                  </div>
                  <div class="field">
                    <label for="publico">{{ t('contact.form.identify') }}</label>
                    <select name="publico" id="publico">
                      <option>{{ t('contact.form.select') }}</option>
                      <option value="industrias_farmaceuticas">{{ t('contact.form.options.industry') }}</option>
                      <option value="operadoras_saude">{{ t('contact.form.options.health_operators') }}</option>
                      <option value="hospitais">{{ t('contact.form.options.hospitals') }}</option>
                      <option value="farmacias_drogarias">{{ t('contact.form.options.pharmacies') }}</option>
                      <option value="associacoes_doencas_raras">{{ t('contact.form.options.associations') }}</option>
                      <option value="sus">{{ t('contact.form.options.public_health') }}</option>
                      <option value="prescritores">{{ t('contact.form.options.prescribers') }}</option>
                      <option value="pacientes">{{ t('contact.form.options.patients') }}</option>
                      <option value="outros">{{ t('contact.form.options.others') }}</option>
                    </select>
                  </div>
                  <div class="field">
                    <label for="mensagem">{{ t('contact.form.message') }}</label>
                    <textarea name="mensagem" id="mensagem"></textarea>
                  </div>
                  <div class="field">
                    <button type="submit" class="button">{{ t('contact.form.submit') }}</button>
                  </div>
                </fieldset>
              </form>
            </div>

            <div class="-contact">
              <div>
                <h3>{{ t('contact.details.location.title') }}</h3>
                <p>{{ t('contact.details.location.description') }}</p>
              </div>
              <div>
                <h3>{{ t('contact.details.contact.title') }}</h3>
                <ul>
                  <li><i class="fa-brands fa-whatsapp"></i> <a href="https://api.whatsapp.com/send/?phone=5511998680834" target="_blank">{{ t('contact.details.contact.whatsapp') }}</a></li>
                  <li><i class="fa-solid fa-envelope"></i> <a href="mailto:atendimento@mypharma2go.com">atendimento@mypharma2go.com</a></li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
    </main>
  </div>
</template>

<script>
export default {
  name: "AppContact",
  data() {
    return {
      bodyClass: 'contact',
    }
  },
  mounted() {
    document.body.classList.add(this.bodyClass)
  },
  beforeUnmount() {
    document.body.classList.remove(this.bodyClass)
  }
}
</script>

<style scoped>
/* Add necessary styles here */
</style>
