<script setup>
import { useI18n } from 'vue-i18n'

const { t } = useI18n()
</script>

<template>
  <div>
    <main>
      <section class="hero">
        <div class="container">
          <div class="content" data-aos="zoom-in" data-aos-duration="2000">
            <h1>{{ t('blog.hero.title') }} <span>{{ t('blog.hero.subtitle') }}</span></h1>
          </div>
        </div>
      </section>

      <section class="intro">
        <div class="container">
          <h2>{{ t('blog.intro.coming_soon') }}</h2>

          <div class="content">
            <div class="-text">
              <h2>{{ t('blog.intro.title') }}</h2>

              <p>{{ t('blog.intro.description1') }}</p>
              <p>{{ t('blog.intro.description2') }}</p>
              <p><strong>{{ t('blog.intro.description3') }}</strong></p>
            </div>

            <div class="-categories">
              <h3>{{ t('blog.categories.title') }}</h3>

              <ul>
                <li>{{ t('blog.categories.item1') }}</li>
                <li>{{ t('blog.categories.item2') }}</li>
                <li>{{ t('blog.categories.item3') }}</li>
                <li>{{ t('blog.categories.item4') }}</li>
                <li>{{ t('blog.categories.item5') }}</li>
              </ul>
            </div>
          </div>
        </div>
      </section>
    </main>
  </div>
</template>

<script>
export default {
  name: "AppBlog",
  data() {
    return {
      bodyClass: 'blog',
    }
  },
  mounted() {
    document.body.classList.add(this.bodyClass)
  },
  beforeUnmount() {
    document.body.classList.remove(this.bodyClass)
  },
}
</script>

<style scoped>
/* Add necessary styles here */
</style>
